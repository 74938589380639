import {Locale} from "./models/locale";
import {enUS, esES} from "@mui/x-date-pickers/locales";

const  defaultLocale = new Locale('en-US', 'English (US)', enUS.components.MuiLocalizationProvider.defaultProps.localeText);

export const supportedLocales = [
    defaultLocale,
    new Locale('es-MX', 'Español (MX)', esES.components.MuiLocalizationProvider.defaultProps.localeText)
]

export const getLocaleFromLanguage = (language: string) => supportedLocales.find(l => l.code === language) || defaultLocale
//export const getLocaleFromLanguage = (language: string) => defaultLocale