import React, {FC, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AppStateStore from '../../stores/AppStateStore'
import {Locale} from "../../models/locale";
import {LocaleSelectorButton, LocaleSelectorLabel, LocaleSelectorMenu} from "./LocaleSelector.styles";

interface LocaleSelectorProps{
    locales: Locale[],
    activeLocale: Locale;
    onLocaleChange: (newLocale: Locale) => void;
}

export const LocaleSelector: FC<LocaleSelectorProps> = ({locales, activeLocale, onLocaleChange}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        
        setAnchorEl(event.currentTarget);
    };
    const handleChange = (newLocale: Locale) => () => {
        setAnchorEl(null);
        onLocaleChange(newLocale)
        AppStateStore.changeLocale(newLocale);        
        // Refresh the page after changing the locale
        window.location.reload();
    };

    return (<div>
        <LocaleSelectorButton
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <LanguageIcon/><LocaleSelectorLabel>{activeLocale.display}</LocaleSelectorLabel><ArrowDropDownIcon/>
        </LocaleSelectorButton>
        <LocaleSelectorMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleChange}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {locales.map(l => <MenuItem key={l.code} onClick={handleChange(l)}>{l.display}</MenuItem>)}
        </LocaleSelectorMenu>
    </div>)
}