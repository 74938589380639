import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import {styled} from "@mui/material/styles";

export const LocaleSelectorButton = styled(Button)`
    font-weight: normal;
    text-transform: none;
    color: inherit;
    border-radius: 10px;
    font-size: 16px;
`

export const LocaleSelectorLabel = styled('span')`
    margin-left: 5px;
      ${props => props.theme.breakpoints.down('laptop')}{
        display: none;
      }
`

export const LocaleSelectorMenu = styled(Menu)`
  .MuiPaper-root{
    border-radius: 10px;
    box-shadow: none;
    border: 1px solid #b3b3b3;
  }`
