import {styled} from '@mui/system';
import DynamicIcon from "../components/DynamicIcon";

export const DesktopHeader = styled('div')`
  padding: 20px 60px;
  margin-bottom: 20px;
  box-shadow: 0 0 6px 6px #ccc;
  display: flex;
  flex-direction: row;
`;

export const DesktopAuthHeader = styled('div')`
  flex: 1;
  text-align: right;
`;

export const DesktopClientLogo = styled('div')`
  width: 300px;
  height: 100px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const DesktopLeftNav = styled('div')`
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.up('laptop')} {
    width: 360px;
  }

  .MuiTreeItem-iconContainer {
    display: none;
  }

  .MuiTreeItem-content {
    padding: 0;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    overflow: hidden;
    display: block;
  }
`;

export const DesktopNavIcon = styled(DynamicIcon)`
  margin-right: 30px;
`

export const DesktopLeftNavItem = styled('div')`
  font-weight: 500;
  padding-left: 15px;
  height: 48px;
  display: flex;
  align-items: center;
  overflow: hidden;
  color: #666;
`;

export const DesktopActionSeparator = styled('div')`
  height: 1px;
  background-color: #999;
  margin: 20px -20px;
`

export const DesktopAction = styled('div')`
  .MuiButton-root {
    font-weight: 500 !important;
  }
  margin: -20px;

  .MuiButton-text {
    text-transform: none;
    padding: 16px;
  }
  .MuiButton-fullWidth {
    text-transform: none !important;
    line-height: 2 !important;
    font-size: .9rem !important;
  }
`

export const DesktopActionCard = styled('div')`
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;

  border: solid 1px #bdbdbd;
  border-radius: 12px;
  padding: 20px;
  max-width: 700px;

  display: flex;
  flex-direction: row;
  min-height: auto;
`;

export const DesktopActionCardContent = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;